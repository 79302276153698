.pagination-container {
  margin-top: 20px;
}

.pagination-table {
  margin-top: 20px;
}

.pagination-column {
  display: flex;
  justify-content: center;
}

.pagination-loading-container {
  display: flex;
  align-items: center;
}
