.charts-container {
  margin-top: 20px;
  height: 100vh;
}

.charts-loading-container {
  display: flex;
  height: 75vh;
  align-items: center;
}

.charts-dashboard {
  margin-top: 20px;
}
