// reactstrap
.navbar {
  //padding: 0;
}

.navbar-brand {
  margin-left: 0;
}

.navigation-bar-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo-text {
  font-family: Roboto Mono, Consolas, Liberation Mono, Courier New, Courier, monospace;
  color: #489be8;
  font-size: 18px;
}
