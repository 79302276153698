.projects-container {
  margin-top: 20px;
}

.first-project-message {
  margin-top: 20px;
}

.project-modal-footer {
  margin-top: 25px;
  padding-bottom: 0;
}

.modal-validation-error {
  margin-top: 10px;
  color: red;
  font-size: 16px;
  font-weight: 400;
}

.projects-loading-container {
  display: flex;
  height: 75vh;
  align-items: center;
}
