.settings-container {
  margin-top: 20px;
}

.user-info {
  display: flex;
}

.user-picture-container {
  margin-right: 15px;
}

.user-picture {
  height: 50px;
}

.user-name {
  line-height: 50px;
}

.user-settings {
  margin-top: 30px;
}

.user-setting-label {
  margin-right: 35px;
}

.user-setting-value {
}

.user-form-group {
  display: flex;
  border: 1px solid red;
  width: 100%;
}

.settings-loading-container {
  display: flex;
  height: 75vh;
  align-items: center;
}
