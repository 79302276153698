.register-container {
  margin-top: 20px;
}

.register-form {
  margin: 15px 0;
}

// bootstrap overrides
.nav-link {
  padding: unset;
}

.register-loading-container {
  display: flex;
  height: 75vh;
  align-items: center;
}
