.login-container {
  margin-top: 20px;
}

.login-form {
  margin: 15px 0;
}

.register-form {
  //margin-bottom: 20px;
}

.forgot-password-link {
  margin-bottom: 20px;
  text-decoration: underline;
}

.register-link {
  margin-bottom: 40px;
  text-decoration: underline;
}

// bootstrap overrides
.nav-link {
  padding: unset;
}

.login-loading-container {
  display: flex;
  height: 75vh;
  align-items: center;
}
