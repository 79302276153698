.home-container {
  margin-top: 20px;
}

.home {
  padding: 1rem;
}

.hello {
  border: 1px solid blue;
  margin-top: 10px;
  padding: 10px;
}

.apps {
  border: 1px solid red;
  margin-top: 10px;
  padding: 10px;

  .name {
    border: 1px solid blue;
    padding: 10px;
  }

  .title {
    border: 1px solid green;
    margin-top: 10px;
    padding: 10px;
  }

  .content {
    margin-top: 10px;
    border: 1px solid green;
    padding: 10px;

    ul {
      margin-bottom: 0;
    }
  }
}

.add-new-link {
  margin-left: 15px;
}
