.files-container {
  margin-top: 20px;
}

.files-loading-container {
  display: flex;
  height: 75vh;
  align-items: center;
}

.file-delete-button {
  margin-left: 10px;
}

.file-upload-container {
  margin-bottom: 20px;
}
